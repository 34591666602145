import { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTenantIdFromLS, getTenantSkFromLS } from 'utils/localStorageUtils';

import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { useToast } from 'context/ToastContext';
import { UserDetailsContext } from 'context/userDetailsContext';

import { toastConstant } from 'constants/toastmessage';

import { generateFullName } from 'utils/generateFullNameUtils';

import { getClientUserList } from 'services/users/userServices';
import { getMessages } from 'services/messages/messageService';
import { useNavbarContext } from 'context/NavbarContext';
import { updateMessageThread } from 'services/messageServices';

// secure message context
const SecureMessageContext = createContext({});

export const useSecureMessageContext = () => useContext(SecureMessageContext);

export const SecureMessageContextProvider = (props) => {
  const {
    isLeadSpecific,
    caseDetails,
    isLeadClosed,
    isGlobalMessagesScreen,
    isFromClientPortal,
    isFromCaseSpecificModule,
    clientPortalDashboard,
    clientDashboardSelectedCase,
    onMountUnMount,
    onOperationBreak,
  } = props;
  let { id } = useParams();

  function getPageLimit() {
    if (isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.message;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.message;
    } else if (isGlobalMessagesScreen && userContext) {
      return userContext?.firmDetails?.pagination_preference?.messages?.self;
    }
  }

  const websocketContext = useContext(WebSocketConnectionContext);

  const userContext = useContext(UserDetailsContext);
  const { notificationData } = useNavbarContext();

  const { addToast } = useToast();

  const [caseMessages, setCaseMessages] = useState([]);
  const [isCaseClosed, setIsCaseClosed] = useState(false);
  const [authorsList, setAuthorsList] = useState([]);
  const [clientsList, setClientsList] = useState([]);

  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [filterCaseId, setFilterCaseId] = useState('');
  const [filterMsgStatus, setFilterMsgStatus] = useState('');
  const [filterAuthorId, setFilterAuthorId] = useState([]);
  const [dateForFilter, setDateForFilter] = useState([]);
  const [filterSentiment, setFilterSentiment] = useState('');
  const [filterParticipant, setFilterParticipant] = useState('');

  const [next_Token, setNext_Token] = useState(null);
  const [transition_Token, setTransition_Token] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!clientPortalDashboard) {
      fetchCaseMessage({});
      let obj = {
        caseId: isGlobalMessagesScreen ? '' : caseDetails?.case_id,
        is_contact_list: false,
        is_team_member_list: false,
        all_contacts: false,
        contact_id: false,
        is_lead: false,
        is_mandatory_lawft_access: true,
        is_active: false,
      };
      getAuthorsList(obj);
    }
  }, []);

  useEffect(() => {
    if (isFromCaseSpecificModule && caseDetails?.sk && caseDetails?.pk) {
      if (caseDetails?.status === 'INACTIVE') {
        setIsCaseClosed(true);
      } else if (caseDetails?.status === 'ACTIVE') {
        setIsCaseClosed(false);
      }
    }
  }, [caseDetails]);

  useEffect(() => {
    if (websocketContext.response) {
      if (websocketContext.response.secure_message_type === 'thread' && websocketContext.response.body) {
        websocketContext.setResponse();
      }
    }
    if (websocketContext.secureMessageData) {
      if (websocketContext.secureMessageData.secure_message_type === 'thread' && websocketContext.secureMessageData.body) {
        websocketContext.setSecureMessageData();
      }
    }
    if (websocketContext.secureMessageData) {
      if (websocketContext.secureMessageData.secure_message_type === 'reply' && websocketContext.secureMessageData.body) {
        websocketContext.setSecureMessageData();
      }
    }
    if (!clientPortalDashboard) {
      fetchCaseMessage({});
    }
  }, [websocketContext.response, websocketContext.secureMessageData]);

  useEffect(() => {
    if (websocketContext.secureMessageError) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.MESSAGE_SEND_FAILURE);
      websocketContext.setSecureMessageError();
    }
  }, [websocketContext.secureMessageError]);

  // fetch message list
  const fetchCaseMessage = (obj) => {
    const tenantId = getTenantIdFromLS();
    const tenant_sk = getTenantSkFromLS();
    let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';

    let idKey = isLeadSpecific ? caseDetails.case_id : id;

    // obj.case_id = isGlobalMessagesScreen ? (obj?.case_id?.value ? obj?.case_id?.value : obj?.case_id) : idKey;
    obj.case_id = isGlobalMessagesScreen ? (obj?.case_id?.id ? obj?.case_id?.id : obj?.case_id) : idKey;
    // obj.case_id = isGlobalMessagesScreen ? (obj?.case_id?.value ? obj.case_id.value : obj?.case_id || obj?.case) : idKey;
    obj.is_global = isGlobalMessagesScreen ? true : false;
    obj.pageLimit = obj.pageLimit ?? getPageLimit();
    obj.tenantId = tenantId;
    obj.tenantSk = tenantSk;
    getMessages(obj)
      .then((response) => {
        setCaseMessages(response.data);
        setNext_Token(response.data.next_token);
        setTransition_Token(response.data.transition_token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getAuthorsList(obj) {
    getClientUserList(obj)
      .then((res) => {
        let data = [];
        res?.data?.forEach((element) => {
          let pkValue = element.client_cognito_username
            ? element.client_cognito_username
            : element.pk && element.pk.split('#') && element.pk.split('#')[element.pk.split('#').length - 1];
          if (element.contact_type) {
            let label = element.contact_type === 'person' ? generateFullName(element) : element.title;
            data.push({
              label: label,
              value: pkValue,
            });
          } else {
            let label = element.first_name || element.middle_name || element.last_name ? generateFullName(element) : element.title;
            data.push({
              label: label,
              value: pkValue,
            });
          }
        });
        setAuthorsList(data);
        setClientsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const refreshCase = () => {
    setSearchKeyWord('');
    setFilterCaseId('');
    setFilterAuthorId('');
    setDateForFilter('');
    setFilterMsgStatus('');
    setFilterSentiment('');
    setFilterParticipant('');

    fetchCaseMessage({});
  };

  const handleMessageStatus = (e, val, isRead, id) => {
    e.preventDefault();
    try {
      if (val && val.sk) {
        const tenantId = getTenantIdFromLS();
        const tenant_sk = getTenantSkFromLS();
        let tenantSk = tenant_sk ? tenant_sk.replaceAll('#', '%23') : '';
        let idKey = isLeadSpecific ? caseDetails.case_id : id;
        let sendData = {};
        sendData.sk = val.sk;
        sendData.is_new = false;
        sendData.case_id = idKey;
        sendData.is_read = !isRead;

        updateMessageThread(tenantId, tenantSk, sendData, false)
          .then(() => {
            //Fetching notification counts
            notificationData?.loadNotificationCount();

            fetchCaseMessage({});
            refreshCase();
          })
          .catch((error) => {
            console.log(error);
            fetchCaseMessage({});
            refreshCase();
          });
      }
    } catch (error) {
      refreshCase();
    }
  };

  const contextPayload = useMemo(
    () => ({
      fetchCaseMessage,
      caseMessages,
      caseDetails,
      isCaseClosed,
      isLeadClosed,
      isLeadSpecific,
      isGlobalMessagesScreen,
      isFromClientPortal,
      isFromCaseSpecificModule,
      next_Token,
      transition_Token,
      authorsList,
      clientsList,
      clientPortalDashboard,
      clientDashboardSelectedCase,
      filterCaseId,
      onMountUnMount,
      onOperationBreak,
      setFilterCaseId,
      filterMsgStatus,
      setFilterMsgStatus,
      filterAuthorId,
      setFilterAuthorId,
      dateForFilter,
      setDateForFilter,
      searchKeyWord,
      setSearchKeyWord,
      refreshCase,
      searchValue,
      setSearchValue,
      handleMessageStatus,
      filterSentiment,
      setFilterSentiment,
      filterParticipant,
      setFilterParticipant,
    }),
    [
      caseMessages,
      caseDetails,
      isCaseClosed,
      isLeadClosed,
      isLeadSpecific,
      isGlobalMessagesScreen,
      isFromClientPortal,
      isFromCaseSpecificModule,
      authorsList,
      clientsList,
      clientPortalDashboard,
      clientDashboardSelectedCase,
      next_Token,
      transition_Token,
      filterCaseId,
      filterMsgStatus,
      filterAuthorId,
      dateForFilter,
      searchKeyWord,
      userContext,
      onMountUnMount,
      onOperationBreak,
      searchValue,
      setSearchValue,
      handleMessageStatus,
      filterSentiment,
      filterParticipant,
    ]
  );

  return <SecureMessageContext.Provider value={contextPayload} {...props} />;
};
