/**
 * React component that renders a header section with filters and avatar list
 * for tasks, and it also fetches user data based on certain conditions.
 * @returns The code is returning a React functional component called "Header".
 */
import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';

import Filter from './Filter';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { useDebounce } from 'hooks/useDebounce';
import { formatMultiSelectContactList, getUserId } from 'utils/utils';
import { listUsers } from 'services/teamManagement.service';
import { totalCountEvent } from 'services/generalServices';
import { getClientUserList } from 'services/CaseServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';

export default function Header() {
  const user_id = getUserId();
  const { userDetails, firmDetails } = useUserDetailsContext();
  const { loadTaskData, isFromCase, caseDetails, isFromLead, isClosed, setAddTaskToggle, totalCount, filterData, setFilterData } =
    useTaskContext();
  const [firmUserIds, setFirmUserIds] = useState(isFromCase ? [] : firmDetails?.user_preferences?.filters?.task?.users || [user_id]);
  const [userList, setUserList] = useState([]);
  const [showUserFilter, setShowUserFilter] = useState(false);

  const isMyTask = useMemo(() => {
    return !Boolean(firmUserIds.includes(user_id));
  }, [firmUserIds, user_id]);

  const debouncedFilterData = useDebounce(filterData, 200);

  const formatEndDate = (value) => {
    if (value === 'past-due') {
      return convertToTimezoneFormat(moment().endOf('day'), userDetails.timezone);
    } else if (value === '3-months') {
      return convertToTimezoneFormat(moment().add(90, 'days').endOf('day'), userDetails.timezone);
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (totalCount !== null) {
      loadTaskData({
        caseId: debouncedFilterData?.case?.id,
        startDate:
          debouncedFilterData?.date === '3-months'
            ? convertToTimezoneFormat(moment().subtract(90, 'days').startOf('day'), userDetails.timezone)
            : undefined,
        end_date: formatEndDate(debouncedFilterData?.date),
        priority: debouncedFilterData?.priority,
        practice_area: debouncedFilterData?.practice_area,
        // data: { firm_user: firmUserIds, all_users: firmUserIds?.length === userList?.length },
        data: {
          firm_user: [...new Set(firmUserIds.flat())],
          all_users: firmUserIds?.length === userList?.[0]?.items?.length + userList?.[1]?.items?.length,
        },
        pageLimit: totalCount,
      });
    }
  }, [debouncedFilterData, firmUserIds, totalCount]);

  const getUserList = () => {
    // To get user list for filter user avatars
    if (isFromCase) {
      getClientUserList(caseDetails?.case_id)
        .then((response) => setUserList(ChangeCaseUserData(response.data)))
        .catch((err) => console.error(err));
    } else if (isFromLead) {
      totalCountEvent('user')
        .then((response) => {
          listUsers('ACTIVE', response.data, '', 'forward', '', '', false, true)
            .then((response) => setUserList(changeUserData(response.data?.firm_users)))
            .catch((err) => console.error(err));
        })
        .catch((error) => console.warn(error));
    } else {
      totalCountEvent('user')
        .then((response) => {
          listUsers('ACTIVE', response.data, '', 'forward', '', '', false, true, true)
            .then((response) => setUserList(formatMultiSelectContactList(response?.data?.firm_users)))
            .catch((err) => console.error(err));
        })
        .catch((error) => console.warn(error));
    }
  };

  const showMyTask = () => {
    isMyTask ? setFirmUserIds([user_id]) : setFirmUserIds([]);
  };

  useEffect(() => {
    if (showUserFilter) {
      getUserList();
    }
  }, [showUserFilter]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        {!Boolean(isFromCase || isFromLead) && <h1 className="title gray-800 mb-0">Tasks</h1>}
        <h5 className="font-bold"> </h5>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-3 task-wraper flex-wrap">
        <Filter
          {...{
            filterData,
            setFilterData,
            isFromCase,
            isFromLead,
            firmUserIds,
            setFirmUserIds,
            userList,
            showUserFilter,
            setShowUserFilter,
          }}
        />
        <div className="d-flex align-items-center py-2 filter-wraper ms-auto">
          <div className="mt-3 mt-md-0">
            <Button onClick={showMyTask} className="secondary-button-grey ms-2">
              {isMyTask ? 'Show My Tasks' : 'Show All Tasks'}
            </Button>
            {!isClosed && (
              <Button
                label="Create Task"
                icon="pi pi-plus"
                className="p-button p-component p-button p-button-primary ms-2"
                onClick={setAddTaskToggle}
                disabled={caseDetails?.status === 'INACTIVE'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const changeUserData = (userList) => {
  try {
    let dat = userList.map((val) => {
      if (val.access_level) {
        let a = { ...val };
        a.user_type = val.access_level;
        a.contact_id = val.user_id;
        a.full_name = generateFullName(val);
        return a;
      } else return val;
    });
    return dat;
  } catch (err) {
    return [];
  }
};

const ChangeCaseUserData = (data) =>
  data.map((v) => ({
    ...v,
    user_id: v.client_cognito_username || v.contact_id,
    full_name: generateFullName(v),
  }));
