import React from 'react';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'primereact/tooltip';

import { useCaseListFilter } from 'hooks/useCaseListFilter';

export default function CaseListDropDown(props) {
  const { isClient = false, caseOnly, disabled = false, ...rest } = props || {};
  const { initialCaseList, onSearchChange } = useCaseListFilter(isClient, caseOnly);
  return (
    <>
      <Tooltip target=".case-tooltip" position="top" />
      <div className="case-tooltip" data-pr-tooltip={rest?.value?.label || ''} data-pr-showdelay="500">
        <AsyncSelect
          {...rest}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 12,
              borderStyle: 'none',
              borderWidth: 'none',
              minWidth: 150,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 9999,
            }),
          }}
          getOptionValue={(option) => option.id}
          isOptionDisabled={(option) => option.value === 'tip'}
          getOptionLabel={(option) => {
            if (isClient) {
              return initialCaseList?.find((item) => item?.id === option?.id)?.label;
            } else return option?.label;
          }}
          placeholder="By Case"
          className="input-shadow w-100"
          cacheOptions
          defaultOptions={initialCaseList}
          loadOptions={onSearchChange}
          noOptionsMessage={() => 'No Cases Found'}
          isDisabled={disabled}
          isSearchable={!isClient}
        />
      </div>
    </>
  );
}
