import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth, Storage } from 'aws-amplify';
import Cookies from 'universal-cookie';

import { Dialog } from 'primereact/dialog';

import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';

import { UserDetailsContext } from 'context/userDetailsContext';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';

import { useLoaderDispatch } from 'context/LoaderContext.jsx';

import { generateFullName } from 'utils/generateFullNameUtils';
import { getTenantIdFromLS, getUserInfoFromLS } from 'utils/localStorageUtils';

import { useSecureMessageContext } from '../Context/SecureMessageContext';
import MessageCreateForm from './MessageCreateForm';
import { getClientUserList } from 'services/users/userServices';
import { listEvents } from 'services/Calender/calendarServices';
const cookie = new Cookies();

function MessageCreateModal({ show, setShow }) {
  const {
    handleSubmit,
    control,
    resetField,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const {
    fetchCaseMessage,
    isLeadSpecific,
    isFromCaseSpecificModule,
    isGlobalMessagesScreen,
    caseDetails,
    isFromClientPortal,
    clientPortalDashboard,
    clientDashboardSelectedCase,
    onMountUnMount,
    onOperationBreak,
  } = useSecureMessageContext();

  let { id } = useParams();

  const userContext = useContext(UserDetailsContext);

  const websocketContext = useContext(WebSocketConnectionContext);
  const setLoader = useLoaderDispatch();

  const [attendeesList, setAttendeesList] = useState([]);

  const [eventAndTaskAllData, setEventAndTaskAllData] = useState({
    events: [],
    tasks: [],
  });
  const [eventData, setEventData] = useState({ events: [], tasks: [] });
  const [files, setFiles] = useState([]);

  const { case_id } = caseDetails ? caseDetails : {};

  const qlEditor = document?.querySelector('.ql-editor');
  const qlHeader = document?.querySelector('.ql-header');

  if (qlHeader) {
    qlHeader.onkeyup = function (e) {
      if (e.key === 'Tab') {
        if (qlEditor) {
          qlEditor.focus();
        }
      }
    };
  }

  useEffect(() => {
    if (case_id) {
      setValue('caseId', case_id);
      getCaseClients(case_id);
    }
    if (clientPortalDashboard) {
      setValue('caseId', clientDashboardSelectedCase.case_id);
      getCaseClients(clientDashboardSelectedCase.case_id);
    }
    //automated time entry logic
    isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('create-message', 'mounted');
    return () => {
      isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
    };
  }, []);

  const getCaseClients = (case_id) => {
    resetField('messageTo');
    resetField('event_attachment');
    resetField('task_attachment');
    resetField('existing_files');
    if (case_id) {
      loadCaseContacts(case_id);
      !Boolean(isFromClientPortal) && getEventData(case_id);
    } else {
      loadCaseContacts();
      !Boolean(isFromClientPortal) && getEventData();
    }
  };

  // get event and task data
  const getEventData = (id = '') => {
    let obj = {
      data: { firm_user: [] },
      case_id: id,
      date_filter_param: 'upcoming',
    };
    listEvents(obj)
      .then((res) => {
        let events = [];
        let tasks = [];

        if (res?.data?.events?.length) {
          events = res.data.events.map((val) => {
            return {
              label: val.title,
              value: val.sk,
            };
          });
        }

        if (res?.data?.tasks?.length) {
          tasks = res.data.tasks.map((val) => {
            return {
              label: val.task_name,
              value: val.sk,
            };
          });
        }
        setEventAndTaskAllData({
          events: res.data.events,
          tasks: res.data.tasks,
        });
        setEventData({ events: events, tasks: tasks });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get case specific contacts
  const loadCaseContacts = (id_case = '') => {
    let obj = {
      caseId: id_case,
      all_contacts: clientPortalDashboard ? null : true,
      contact_id: isGlobalMessagesScreen || clientPortalDashboard ? userContext?.userDetails?.contact_id : false,
      is_team_member_list: clientPortalDashboard ? null : true,
      is_include_groups: true,
      active_groups: true,
    };

    getClientUserList(obj)
      .then((response) => {
        if (response && response.data && response.data.length) {
          let users = [];
          let groups = [];
          response.data.forEach((element) => {
            if (element?.res_type === 'user-group') {
              groups.push({
                label: element.group_name,
                value: element.user_group_id,
                ...element,
              });
            } else {
              function generateLabel(element) {
                if (element.contact_type === 'person' || element.first_name || element.middle_name || element.last_name) {
                  return generateFullName(element);
                }
                return element.title;
              }

              const label = generateLabel(element);

              users.push({
                label: label,
                value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
                email: element.email,
                is_create_client_portal: element.is_create_client_portal,
                access_level: element.access_level,
                ...element,
              });
            }
          });
          let grouped_data = [];
          if (groups.length > 0) {
            grouped_data.push({
              label: 'Groups',
              items: groups,
            });
          }
          if (users.length > 0) {
            grouped_data.push({
              label: 'Users',
              items: users.filter((user) => user.firm_user_id !== userContext?.userDetails?.firm_user_id),
            });
          }
          grouped_data.reverse();
          setAttendeesList(grouped_data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function fileUpload(caseIdVal) {
    let attachments = [];
    if (files && files.length > 0) {
      setLoader(true);
      try {
        const tenantId = (await cookie.get('tenantId')) || '';
        const moduleName = 'messages';
        const userId = userContext?.userDetails?.firm_user_id;

        let case_Id;
        if (isGlobalMessagesScreen && caseIdVal) {
          case_Id = caseIdVal;
        } else {
          case_Id = case_id;
        }

        for (let i = 0; i < files.length; i++) {
          SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
          let user = await Auth.currentAuthenticatedUser();
          //TODO:multi-tenancy change
          // userId = user.attributes.sub; //In this userId is equal to the cognito_username . For multi-tenancy we need to replace cognito_username with either firm_use_id or contact_id
          // let response = await Storage.put(`${moduleName}/${case_Id ? case_Id + '/' : ''}${userId}/${files[i].name}`, files[i], {

          let response = await Storage.put(
            `${moduleName}/${tenantId}/${userId}/${case_Id ? case_Id + '/' : ''}${files[i].name}`,
            files[i],
            {
              contentType: files[i].type,
              progressCallback: (progress) => {
                const { loaded, total } = progress;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                }
              },
              useAccelerateEndpoint: true,
            }
          );
          if (response.key) {
            let file_type = files[i].type.toString();
            let file_details = {
              file_name: files[i].name,
              display_name: files[i].name,
              size: files[i].size.toString(),
              file_path: 'public/' + response.key,
              file_type: file_type,
              created_by: user.attributes,
            };
            attachments.push(file_details);
          }
        }
        setLoader(false);
        return attachments;
      } catch (err) {
        setLoader(false);
        console.log(err);
        return attachments;
      }
    } else {
      setLoader(false);
      return attachments;
    }
  }

  const handleClose = () => {
    setShow(false);
  };

  const createMessage = (data) => {
    const usersArray = attendeesList?.find((item) => item.label === 'Users')?.items;

    const assign_to = data.messageTo.flatMap((val) => {
      const fullData = usersArray?.find((value) => {
        const optionValue = value.client_cognito_username || value.contact_id;
        return optionValue === val;
      });

      if (!fullData) {
        return [];
      }

      const recipient_id = fullData.client_cognito_username ?? fullData.contact_id;

      return [
        {
          recipient_name: generateFullName(fullData),
          recipient_id: recipient_id,
          client_cognito_username: fullData.client_cognito_username,
          firm_user_id: fullData.firm_user_id ?? '',
          contact_id: fullData.contact_id ?? '',
          email: fullData.email,
          access_level: fullData.access_level,
        },
      ];
    });

    const groupsArray = attendeesList?.find((item) => item.label === 'Groups')?.items;

    const groups = data.messageTo.flatMap((val) => {
      const fullGroupData = groupsArray?.find((group) => {
        return group.user_group_id === val;
      });

      if (!fullGroupData) {
        return [];
      }

      const obj = {
        group_name: fullGroupData.group_name,
        group_id: fullGroupData.user_group_id,
        sk: fullGroupData.sk,
      };

      return [obj];
    });

    // tasks data
    var finalTask = [];
    if (data.task_attachment && data.task_attachment.length) {
      finalTask = data.task_attachment.map((task) => {
        let val = eventAndTaskAllData.tasks.find((value) => value.sk === task);
        if (val) {
          return {
            assign_to: assign_to,
            ...val,
          };
        }
      });
    }

    // events data
    let finalEvents = [];
    if (data.event_attachment && data.event_attachment.length) {
      finalEvents = data.event_attachment.map((event) => {
        let val = eventAndTaskAllData.events.find((value) => value.sk === event);
        if (val) {
          return {
            event_name: val.title,
            ...val,
          };
        }
      });
    }

    let sendData = {};

    const tenantId = getTenantIdFromLS();

    if (assign_to?.length > 0) {
      sendData.recipients = assign_to?.map((index) => index.recipient_id);
    }

    sendData.action = 'message';
    sendData.type = 'secure_message';
    sendData.data = {};
    sendData.data.secure_message_type = 'thread';
    sendData.data.tenant_id = tenantId;
    sendData.data.body = {};
    sendData.data.body.message_to = assign_to;
    sendData.data.body.groups = groups;
    sendData.data.body.subject = data.subject;
    sendData.data.body.message = data.message;
    sendData.data.body.attachment = data.attachments;

    sendData.data.body.event_attachment = finalEvents;
    sendData.data.body.task_attachment = finalTask;

    if (isGlobalMessagesScreen || clientPortalDashboard) {
      sendData.case_id = data?.caseId?.id;
      sendData.data.body.case_id = data.caseId?.id;
      sendData.data.body.case_name = data.caseName;
    } else {
      let idKey = isLeadSpecific ? case_id : id;
      sendData.case_id = idKey;
      sendData.data.body.case_id = idKey;
      sendData.data.body.is_lead = isLeadSpecific ? true : false;
      if (caseDetails && caseDetails.case_description && caseDetails.case_description.case_name) {
        sendData.data.body.case_name = caseDetails.case_description.case_name;
      }
    }

    let userInfo = getUserInfoFromLS();

    if (userInfo && userInfo.attributes) {
      sendData.creator_first_name = userInfo.attributes['custom:first_name'];
      sendData.creator_last_name = userInfo.attributes['custom:last_name'];
      sendData.creator_email = userInfo.attributes['email'];
    }

    if (clientPortalDashboard) {
      sendData.contact_id = userContext?.userDetails?.contact_id;
    }

    websocketContext
      .sendMessage(sendData)
      .then(() => {
        isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('create-message', 'unmounted');
        !clientPortalDashboard && fetchCaseMessage({});
        handleClose();
      })
      .catch((err) => {
        isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
      });
  };

  // submit create message form
  const onSubmit = async (data) => {
    if (isLeadSpecific || isFromCaseSpecificModule) {
      data.caseName =
        caseDetails && caseDetails.case_description && caseDetails.case_description.case_name
          ? caseDetails.case_description.case_name
          : 'TBD';
    } else {
      data.caseName = data?.caseId?.case_name;
    }
    data.attachments = await fileUpload(data?.caseId?.id);
    if (data.existing_files && data.existing_files.length > 0) {
      data.attachments = [...data.attachments, ...data.existing_files];
    }
    createMessage(data);
  };

  const headerComponent = (data) => {
    return (
      <div>
        <i className="icon-window-filled F-size14 pointer" onClick={data?.onHide}></i>
        {/* <i className="icon-window-filled F-size14 icon-minimize"></i> */}
      </div>
    );
  };

  return (
    <Dialog header={headerComponent} visible={show} onHide={handleClose} className="create-msg-popup" closable={false}>
      <MessageCreateForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        errors={errors}
        resetField={resetField}
        control={control}
        setError={setError}
        watch={watch}
        attendeesList={attendeesList}
        eventData={eventData}
        setFiles={setFiles}
        files={files}
        clientPortalDashboard={clientPortalDashboard}
        getCaseClients={getCaseClients}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default MessageCreateModal;
