export const dateFilterOptions = [
  { label: '3 months before and after', value: '3-months' },
  { label: 'All time', value: 'All time' },
  { label: 'Past due', value: 'past-due' },
];

export const priorityList = [
  { label: 'No Priority', value: 'No Priority' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

export const timeIntervals = [
  { label: '1 day before', value: '1 day before' },
  { label: '3 days before', value: '3 days before' },
  { label: '7 days before', value: '7 days before' },
];

export const timeIntervalsWithServerValue = [
  { label: '1 day before', value: '1#days#', days: 1 },
  { label: '3 days before', value: '3#days#', days: 3 },
  { label: '7 days before', value: '7#days#', days: 7 },
];

// Constants from Contact Module
export const types = [
  { label: 'Person', value: 'person' },
  { label: 'Business', value: 'business' },
  { label: 'Location', value: 'location' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
];

export const titles = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Miss.', value: 'Miss.' },
];

export const contactTypes = [
  { label: 'Show All', value: 'All' },
  { label: 'Person', value: 'person' },
  { label: 'Location', value: 'location' },
  { label: 'Business', value: 'business' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
];

export const status = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'ARCHIVED' },
]; // for contact filter page

export const paginationDropdownOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const leadsPagesOptions = [
  {
    label: 'Overview',
    value: 'dashboard',
    icon: 'icon-overview',
    icon_active: 'icon-overview-filled',
    path: '',
  },
  {
    label: 'Information',
    value: 'information',
    icon: 'icon-information',
    icon_active: 'icon-information-filled',
    path: 'info',
  },
  {
    label: 'Files',
    value: 'files',
    icon: 'icon-files',
    icon_active: 'icon-files-filled',
    path: 'files',
  },
  {
    label: 'Contacts',
    value: 'contacts',
    icon: 'icon-contacts',
    icon_active: 'icon-contacts-filled',
    path: 'contacts',
  },
  {
    label: 'Calendar',
    value: 'calendar',
    icon: 'icon-calendar',
    icon_active: 'icon-calendar-filled',
    path: 'calendar',
  },
  {
    label: 'Tasks',
    value: 'tasks',
    icon: 'icon-tasks',
    icon_active: 'icon-tasks-filled',
    path: 'task',
  },
  {
    label: 'Messages',
    value: 'messages',
    icon: 'icon-messages',
    icon_active: 'icon-messages-filled',
    path: 'messages',
  },
  {
    label: 'Text Messages',
    value: 'text-messages',
    icon: 'icon-messages',
    icon_active: 'icon-messages-filled',
    path: 'text-message',
  },
  {
    label: 'Notes/Call Logs',
    value: 'notes',
    icon: 'icon-notes',
    icon_active: 'icon-notes-filled',
    path: 'note',
  },
  {
    label: 'Billing',
    value: 'billing',
    icon: 'icon-money',
    icon_active: 'icon-money-filled',
    path: 'billing',
  },
  {
    label: 'Activity Log',
    value: 'activitylog',
    icon: 'icon-activity',
    icon_active: 'icon-activity-filled',
    path: 'activity',
  },
];

export const casePageOptions = [
  {
    label: 'Overview',
    value: 'dashboard',
    icon: 'icon-overview',
    icon_active: 'icon-overview-filled',
    path: '',
  },
  {
    label: 'Information',
    value: 'information',
    icon: 'icon-information',
    icon_active: 'icon-information-filled',
    path: 'info',
  },
  {
    label: 'Files',
    value: 'files',
    icon: 'icon-files',
    icon_active: 'icon-files-filled',
    path: 'files',
  },
  {
    label: 'Contacts',
    value: 'contacts',
    icon: 'icon-contacts',
    icon_active: 'icon-contacts-filled',
    path: 'contacts',
  },
  {
    label: 'Calendar',
    value: 'calendar',
    icon: 'icon-calendar',
    icon_active: 'icon-calendar-filled',
    path: 'calendar',
  },
  {
    label: 'Tasks',
    value: 'tasks',
    icon: 'icon-tasks',
    icon_active: 'icon-tasks-filled',
    path: 'tasks',
  },
  {
    label: 'Messages',
    value: 'messages',
    icon: 'icon-messages',
    icon_active: 'icon-messages-filled',
    path: 'messages',
  },
  {
    label: 'Text Messages',
    value: 'text-messages',
    icon: 'icon-textmessage',
    icon_active: 'icon-textmessage-filled',
    path: 'text-messages',
  },
  {
    label: 'Notes/Call Logs',
    value: 'notes',
    icon: 'icon-notes',
    icon_active: 'icon-notes-filled',
    path: 'notes',
  },
  {
    label: 'Medical / Case Expenses',
    value: 'expenses',
    icon: 'icon-expenses',
    icon_active: 'icon-expenses-filled',
    path: 'expenses',
  },
  {
    label: 'Billing',
    value: 'billing',
    icon: 'icon-money',
    icon_active: 'icon-money-filled',
    path: 'billing',
  },
  // { label: 'Timeline', value: "timeline", icon: 'icon-clock',icon_active: "icon-clock-filled" },
  {
    label: 'Activity Log',
    value: 'activitylog',
    icon: 'icon-activity',
    icon_active: 'icon-activity-filled',
    path: 'activity',
  },
];

export const paymentFrequency = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-weekly', value: 'biweekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
];
export const reminderOptions = [
  { label: '30 minutes before the meeting', value: '30#minutes#' },
  { label: '1 day before the meeting', value: '1#days#' },
  { label: '2 days before the meeting', value: '2#days#' },
  { label: '1 week before the meeting', value: '1#weeks#' },
  { label: 'custom', value: 'custom' },
];
export const reminderCustomOptions = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
];

export const reminderCountOptions = Array.from({ length: 60 }, (_, i) => i + 1).map((index) => {
  return {
    label: index,
    value: index,
  };
});

export const calendarCategories = [
  { label: 'Show All', value: 'All' },
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Statute of Limitation',
    value: 'case',
  },
  {
    label: 'Task',
    value: 'task',
  },
];

export const expenseStatusOptions = [
  // { label: 'Invoiced/Uninvoiced', value: '' },
  { label: 'All', value: '' },
  { label: 'Invoiced', value: 'INVOICED' },
  { label: 'Uninvoiced', value: 'UNINVOICED' },
];
export const caseStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
  { label: 'All', value: 'all' },
];

export const caseStatusOptionsReport = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { label: 'All', value: '' },
];

export const leadStatusOptionsReport = [
  { label: 'All', value: 'both' },
  { label: 'Open', value: 'special' },
  { label: 'Closed', value: 'closed' },
  { label: 'Converted', value: 'open' },
];

export const refferalStatusReportOptions = [
  { label: 'All', value: '' },
  { label: 'Open', value: 'special' },
  { label: 'Closed', value: 'closed' },
];

export const timePastDueReportOptions = [
  { label: '0 - 30 days', value: 30 },
  { label: '31 - 60 days', value: 60 },
  { label: '61 - 90 days', value: 90 },
  { label: 'Over 90 days', value: 100 },
];

export const caseValueFilter = [
  { label: '0-5000', value: { price_range_lower: -1, price_range_upper: 5000 } },
  { label: '5001-10000', value: { price_range_lower: 5001, price_range_upper: 10000 } },
  { label: '10001-20000', value: { price_range_lower: 10001, price_range_upper: 20000 } },
  { label: '20001-50000', value: { price_range_lower: 20001, price_range_upper: 50000 } },
  { label: '50001-100,000', value: { price_range_lower: 50001, price_range_upper: 100000 } },
  { label: '100k-250k', value: { price_range_lower: 100001, price_range_upper: 250000 } },
  { label: '250k-500k', value: { price_range_lower: 250001, price_range_upper: 500000 } },
  { label: '500k-1M', value: { price_range_lower: 500001, price_range_upper: 1000000 } },
  { label: '1M-2M', value: { price_range_lower: 1000001, price_range_upper: 2000000 } },
  { label: '2M-5M', value: { price_range_lower: 1000001, price_range_upper: 5000000 } },
  { label: '5M-10M', value: { price_range_lower: 5000001, price_range_upper: 10000000 } },
  { label: '10M-25M', value: { price_range_lower: 10000001, price_range_upper: 25000000 } },
  { label: '25M+', value: { price_range_lower: 25000000, price_range_upper: 9999999999 } },
];

export const financeReportPaymentOptions = [
  { label: 'Payment Request', value: 'payment_request' },
  { label: 'Invoice', value: 'invoice' },
];

export const callTypeOptions = [
  { label: 'Incoming', value: 'incoming' },
  { label: 'Outgoing', value: 'outgoing' },
];

export const noteTypeOptions = [
  { name: 'Case Note', value: 'case_note' },
  { name: 'Call Log', value: 'call_log' },
];

export const notifyUpdateOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },
  { label: 'Event Based', value: 'event_based' },
];

export const caseNoteCallLogFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Notes', value: 'case_note' },
  { label: 'Call Log', value: 'call_log' },
];

export const paymentStructure = {
  FLAT_FEE: 'Flat Fee',
  SUBSCIPTION: 'Subscription',
  CONTINGENCY_FEE: 'Contingency Fee',
  EVERGREEN_RETAINER: 'Evergreen Retainer',
  STANDARD_RETAINER: 'Standard Retainer',
};
export const paymentMethods = [
  {
    label: 'Credit Card',
    value: 'Credit Card',
  },
  { label: 'Check', value: 'Check' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Other', value: 'other' },
];

export const accountOptions = [
  { label: 'Trust', value: 'trust' },
  { label: 'Operating', value: 'operating' },
];

export const trackedTimeOptions = [
  { label: 'Today', value: 'day' },
  {
    label: 'This Week',
    value: 'week',
  },
  { label: 'This Month', value: 'month' },
  { label: 'This Quarter', value: 'quarterly' },
];

export const agendaOptions = [
  { label: 'Events', value: 'event' },
  { label: 'Tasks', value: 'task' },
];

export const taskOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Overdue', value: 'overdue' },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Invoiced', value: 'INVOICED' },
  { label: 'Uninvoiced', value: 'OPEN' },
];

export const billingOptions = [
  { label: 'All', value: '' },
  { label: 'Billable', value: 'billable' },
  { label: 'Non-Billable', value: 'nonbillable' },
];

export const msgStatusOptions = [
  { label: 'Show All', value: 'All' },
  { label: 'Read', value: true },
  { label: 'Unread', value: false },
];

export const practiceAreaStatusOptions = [
  { label: 'All', value: '' },
  { label: 'Archive', value: 'ARCHIVED' },
  { label: 'Unarchive', value: 'ACTIVE' },
];

export const teamMemberRoles = [
  { label: 'Lead Associate', value: 'is_lead_associate' },
  { label: 'Lead Attorney', value: 'is_lead_attorney' },
  { label: 'Lead Paralegal', value: 'is_lead_paralegal' },
  { label: 'Lead Case Manager', value: 'is_lead_case_manager' },
  { label: 'Attorney', value: '' },
];

export const portalOptions = [
  { label: 'Enable', value: 'enable' },
  { label: 'Disable', value: 'disable' },
];

export const sentimentOptions = [
  { label: 'Positive', value: 'POSITIVE' },
  { label: 'Negative', value: 'NEGATIVE' },
  { label: 'Mixed', value: 'MIXED' },
  { label: 'Neutral', value: 'NEUTRAL' },
];

export const participantOptions = [
  { label: 'Within Firm', value: 'within_firm' },
  { label: 'Client-Involved', value: 'client_involved' },
];
